.symbol-list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
  background-color: transparent;
  padding: 10px;
  border-radius: 5px;
}

.symbol-item {
  background-color: transparent;
  border: none;
  padding: 5px 10px;
  font-size: 13px;
  font-weight: bold;
  text-align: left;
  display: inline-flex;
  align-items: center;
  color: gold; /* Set the color of the token symbol to gold */
}

.symbol-item:not(:first-child)::before {
  content: " ";
}

.symbol-index {
  color: rgb(255, 255, 255); /* Set the color of the index to black */
  margin-right: 5px;
}

.symbol-text {
  color: gold; /* Set the color of the token symbol to gold */
}
